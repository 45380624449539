const registryV4abi = require('./registryV4abi.json');

module.exports = {
  "public": {

    // Ethereum
    "1": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "16227060",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.etherscan.io/api",
      "data": {
        "name": "Ethereum",
        "symbol": "ETH",
        "gecko": "ethereum"
      },
      "testnet": false
    },
    // Goerli
    // "5": {
    //   "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
    //   "rcAbi": registryV3abi,
    //   "rcBlock": "8110721",
    //   "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
    //   "verifyApi": "https://api-goerli.etherscan.io/api",
    //   "testnet": true
    // },
    // Sepolia
    "11155111": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "5652976",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-sepolia.etherscan.io/api",
      "testnet": true
    },
    // Holesky
    "17000": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "1304936",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-holesky.etherscan.io/api",
      "testnet": true
    },

    // BSC
    "56": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "24071653",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.bscscan.com/api", // https://api.bscscan.com/api
      "data": {
        "name": "BNB Smart Chain",
        "symbol": "BNB",
        "gecko": "binancecoin"
      },
      "testnet": false
    },
    // BSC Testnet
    "97": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "25304946",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-testnet.bscscan.com/api",
      "testnet": true
    },

    // Polygon
    "137": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "37063635",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.polygonscan.com/api",
      "data": {
        "name": "Polygon",
        "symbol": "POL",
        "gecko": "matic-network"
      },
      "testnet": false
    },
    // Polygon Mumbai
    // "80001": {
    //   "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
    //   "rcAbi": registryV3abi,
    //   "rcBlock": "39703609",
    //   "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
    //   "verifyApi": "https://api-testnet.polygonscan.com/api",
    //   "testnet": true
    // },
    // Polygon Amoy
    "80002": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "9016076",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-amoy.polygonscan.com/api",
      "testnet": true
    },

    // Avalanche
    "43114": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "23888750",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.snowscan.xyz/api",
      "data": {
        "name": "Avalanche C-Chain",
        "symbol": "AVAX",
        "gecko": "avalanche-2"
      },
      "testnet": false
    },
    // Avalanche Fuji
    "43113": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "25511661",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-testnet.snowscan.xyz/api",
      "testnet": true
    },

    // Fantom
    "250": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "52588189",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.ftmscan.com/api",
      "data": {
        "name": "Fantom",
        "symbol": "FTM",
        "gecko": "fantom"
      },
      "testnet": false
    },
    // Fantom Testnet
    "4002": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "20272643",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-testnet.ftmscan.com/api",
      "testnet": true
    },

    // Arbitrum
    "42161": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "47617675",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.arbiscan.io/api",
      "data": {
        "name": "Arbitrum One",
        "symbol": "ETH",
        "gecko": "ethereum"
      },
      "testnet": false
    },

    // Optimism
    "10": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "97226028",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-optimistic.etherscan.io/api",
      "data": {
        "name": "Optimism",
        "symbol": "ETH",
        "gecko": "ethereum"
      },
      "testnet": false
    },

    // Cronos
    "25": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "7606507",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.cronoscan.com/api",
      "data": {
        "name": "Cronos",
        "symbol": "CRO",
        "gecko": "crypto-com-chain"
      },
      "testnet": false
    },

    // PulseChain
    "369": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "17388844",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "",
      "data": {
        "name": "PulseChain",
        "symbol": "PLS",
        "gecko": "pulsechain"
      },
      "testnet": false
    },

    // Base
    "8453": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "2001251",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.basescan.org/api",
      "data": {
        "name": "Base",
        "symbol": "ETH",
        "gecko": "ethereum"
      },
      "testnet": false
    },

    // Core
    "1116": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "3712191",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "",
      "data": {
        "name": "Core",
        "symbol": "CORE",
        "gecko": "coredaoorg"
      },
      "testnet": false
    },

    // Evmos
    // "9001": {
    //   "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
    //   "rcAbi": registryV3abi,
    //   "rcBlock": "13215784",
    //   "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
    //   "verifyApi": "",
    //   "data": {
    //     "name": "Evmos",
    //     "symbol": "EVMOS",
    //     "gecko": "evmos"
    //   },
    //   "testnet": false
    // },

    // Linea
    "59144": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "1220599",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.lineascan.build/api",
      "data": {
        "name": "Linea",
        "symbol": "ETH",
        "gecko": "ethereum"
      },
      "testnet": false
    },

    // Mantle
    "5000": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "27152656",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.mantlescan.xyz/api",
      "data": {
        "name": "Mantle",
        "symbol": "MNT",
        "gecko": "mantle"
      },
      "testnet": false
    },

    // Blast
    "81457": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "1623452",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.blastscan.io/api",
      "data": {
        "name": "Blast",
        "symbol": "ETH",
        "gecko": "ethereum"
      },
      "testnet": false
    },

    // DegenChain
    "666666666": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "1736428",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "",
      "data": {
        "name": "DegenChain",
        "symbol": "DEGEN",
        "gecko": "degen-base"
      },
      "testnet": false
    },

    // Scroll
    "534352": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "6035487",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.scrollscan.com/api",
      "data": {
        "name": "Scroll",
        "symbol": "ETH",
        "gecko": "ethereum"
      },
      "testnet": false
    },

    // Metis
    "1088": {
      "rc": "0x896cB15542A50e084CB01138211daA110b1Fe8F2",
      "rcAbi": registryV4abi,
      "rcBlock": "17179428",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://andromeda-explorer.metis.io/api",
      "data": {
        "name": "Metis",
        "symbol": "METIS",
        "gecko": "metis-token"
      },
      "testnet": false
    }
  },
  "private": {
    // Ethereum
    "1": {
      "url": "https://eth-mainnet.nodereal.io/v1/15d3297dca744d8f9ba4fdc883cd5067",
      "urlAlt": "https://eth-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "verifyApiKey": "VJ5QB8IKE15XDDKC2MZXPB7X4V4PIZ4ZUJ",
      "storage": "0x12a4c5A8f6df92c8a1380Fc7901d0E42A03d5749",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },
    // Goerli
    // "5": {
    //   "url": "https://eth-goerli.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
    //   "urlAlt": "https://goerli.blockpi.network/v1/rpc/public",
    //   "verifyApiKey": "VJ5QB8IKE15XDDKC2MZXPB7X4V4PIZ4ZUJ",
    //   "storage": "0xdDCd5e8141c2c151b9731B67A9955c3bC3400b91"
    // },
    // Sepolia
    "11155111": {
      "url": "https://eth-sepolia.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://ethereum-sepolia-rpc.publicnode.com",
      "verifyApiKey": "VJ5QB8IKE15XDDKC2MZXPB7X4V4PIZ4ZUJ",
      "storage": "0x572e8EC8a91a10E26e3518f07ac80297617a6282",
      "pricesUSD": {
        "base": 0,
      }
    },
    // Holesky
    "17000": {
      "url": "https://eth-holesky.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://ethereum-holesky-rpc.publicnode.com",
      "verifyApiKey": "VJ5QB8IKE15XDDKC2MZXPB7X4V4PIZ4ZUJ",
      "storage": "0x572e8EC8a91a10E26e3518f07ac80297617a6282",
      "pricesUSD": {
        "base": 0,
      }
    },

    // BSC
    "56": {
      "url": "https://bsc-mainnet.nodereal.io/v1/15d3297dca744d8f9ba4fdc883cd5067",
      "urlAlt": "https://bsc-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "verifyApiKey": "UPDXXY7TBM99134DHXB32ZKQAYNWA64J77",
      "storage": "0xf53F26A5d402FC78d3D5be320c0D6B22Db60B760",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },
    // BSC Testnet
    "97": {
      "url": "https://bsc-testnet.nodereal.io/v1/15d3297dca744d8f9ba4fdc883cd5067",
      "urlAlt": "https://bsc-testnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "verifyApiKey": "UPDXXY7TBM99134DHXB32ZKQAYNWA64J77",
      "storage": "0x3B6E6e3F0f528FCb10D3AdFf9c6271a80E3EBE77",
      "pricesUSD": {
        "base": 0,
      }
    },

    // Polygon
    "137": {
      "url": "https://polygon-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1", //https://1rpc.io/matic
      "urlAlt": "https://polygon.blockpi.network/v1/rpc/public",
      "verifyApiKey": "T7WFEPV18YA4763Y4DIUXXH4EIXKAUAV8C",
      "storage": "0x170f0493749ed5393326b2389EEBbC1DF73768E8",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },
    // Polygon Mumbai
    // "80001": {
    //   "url": "https://polygon-testnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
    //   "urlAlt": "https://polygon-mumbai.blockpi.network/v1/rpc/public",
    //   "verifyApiKey": "T7WFEPV18YA4763Y4DIUXXH4EIXKAUAV8C",
    //   "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    // },
    // Polygon Amoy
    "80002": {
      "url": "https://polygon-amoy.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://polygon-amoy.blockpi.network/v1/rpc/public",
      "verifyApiKey": "T7WFEPV18YA4763Y4DIUXXH4EIXKAUAV8C",
      "storage": "0x572e8EC8a91a10E26e3518f07ac80297617a6282",
      "pricesUSD": {
        "base": 0,
      }
    },

    // Avalanche
    "43114": {
      "url": "https://ava-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1/ext/bc/C/rpc",
      "urlAlt": "https://avalanche.blockpi.network/v1/rpc/public",
      "verifyApiKey": "QA1DY3X7G5PHWHM9RYB4G4EJYM53RHTWY3",
      "storage": "0x5Fb17345A5AE6B48C799F54e2A4085F588C4A1f7",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },
    // Avalanche Fuji
    "43113": {
      "url": "https://ava-testnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1/ext/bc/C/rpc",
      "urlAlt": "https://avalanche-fuji.blockpi.network/v1/rpc/public",
      "verifyApiKey": "QA1DY3X7G5PHWHM9RYB4G4EJYM53RHTWY3",
      "storage": "0x702d9f38B96dA51c3Da6f5Ca8b0f2dcef7b1e898",
      "pricesUSD": {
        "base": 0,
      }
    },

    // Fantom
    "250": {
      "url": "https://fantom-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://fantom.blockpi.network/v1/rpc/public",
      "verifyApiKey": "9IUR7ZSNR946SZE5PSKE7U2MCJVD8DRQPV",
      "storage": "0x7F2dc782e32342D8BefDCB502897deF58591ECcc",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },
    // Fantom Testnet
    "4002": {
      "url": "https://fantom-testnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://rpc.testnet.fantom.network",
      "verifyApiKey": "9IUR7ZSNR946SZE5PSKE7U2MCJVD8DRQPV",
      "storage": "0x702d9f38B96dA51c3Da6f5Ca8b0f2dcef7b1e898",
      "pricesUSD": {
        "base": 0,
      }
    },

    // Arbitrum
    "42161": {
      "url": "https://arbitrum-one.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://arbitrum.blockpi.network/v1/rpc/public",
      "verifyApiKey": "R6NIKS61DAJ5MIPW859VZPD4KFB7U5UNPP",
      "storage": "0x93a7d8a44bA3a912c022C99d8Db15A355726b651",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },

    // Optimism
    "10": {
      "url": "https://optimism-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://optimism.blockpi.network/v1/rpc/public",
      "verifyApiKey": "KHMXQ1WI3H1EISSP4Q396GHAWNFG9ETQBQ",
      "storage": "0xc702499e292459CAD9b92ecA65B686b2378180e8",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },

    // Cronos
    "25": {
      "url": "https://cronos.blockpi.network/v1/rpc/public",
      "urlAlt": "https://evm.cronos.org",
      "verifyApiKey": "GJVW671W3TQTP3HFACSW4H2S5TBK7J6ND1",
      "storage": "0x6Ac3a1E41880016e5d4308572344d9948D7eB855",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },
    
    // PulseChain
    "369": {
      "url": "https://rpc.pulsechain.com",
      "urlAlt": "https://pulsechain.publicnode.com",
      "verifyApiKey": "",
      "storage": "0xfd956f5b34c2e92a240c63D2BEbeAc45586fCE72",
      "pricesUSD": {
        "base": 85,
        "optional": 30,
        "taxes": 30,
      }
    },

    // Base
    "8453": {
      "url": "https://open-platform.nodereal.io/15d3297dca744d8f9ba4fdc883cd5067/base",
      "urlAlt": "https://base-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "verifyApiKey": "ZA3WV64KUBZV99N3M4RFU89S58VPBI71EI",
      "storage": "0x4457c38A780d7Ee66d49A9Dd26738303F667DC99",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },

    // Core
    "1116": {
      "url": "https://rpc.coredao.org",
      "urlAlt": "https://1rpc.io/core",
      "verifyApiKey": "",
      "storage": "0x129BFb0AcF26A84D5740ea8c6BD50703C9f73C30",
      "pricesUSD": {
        "base": 85,
        "optional": 30,
        "taxes": 30,
      }
    },

    // Evmos
    // "9001": {
    //   "url": "https://evmos-evm.publicnode.com",
    //   "urlAlt": "https://evmos-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
    //   "verifyApiKey": "",
    //   "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    // },

    // Linea
    "59144": {
      "url": "https://linea-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://linea.blockpi.network/v1/rpc/public",
      "verifyApiKey": "PSKJ1EY8Q11PZ46AWA9FMY2FPGSSYJRSND",
      "storage": "0xB1877dD448D123577E8C1F5d4ed1991fe418302F",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },

    // Mantle
    "5000": {
      "url": "https://mantle-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://rpc.mantle.xyz",
      "verifyApiKey": "SS7IPPTYGB5W8ZQ7F294NZ1RVS8UJYITJQ",
      "storage": "0xF75EeC7bdb78d2216d3C3FC0B65BDBE21eBc700C",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },

    // Blast
    "81457": {
      "url": "https://blastl2-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://rpc.blast.io",
      "verifyApiKey": "TG8IHNI5646PAA9DP8WI3IW82GPP2CKF4B",
      "storage": "0xfAE59375fc1930cf97e8427800a72084485c7e51",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },

    // DegenChain
    "666666666": {
      "url": "https://rpc.degen.tips",
      "urlAlt": "https://rpc.degen.tips",
      "verifyApiKey": "",
      "storage": "0x50D8B0dC0FE47Bfb7BB351Ec129120291C9f6593",
      "pricesUSD": {
        "base": 85,
        "optional": 30,
        "taxes": 30,
      }
    },

    // Scroll
    "534352": {
      "url": "https://scroll-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://scroll.blockpi.network/v1/rpc/public",
      "verifyApiKey": "C1KRQRMSNGTA33I9ZW2UMTU47CE3PFDJG1",
      "storage": "0xbC794dfeA2e917e609CCA42AE8C1fe01f40984F6",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    },

    // Metis
    "1088": {
      "url": "https://metis-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://metis-pokt.nodies.app",
      "verifyApiKey": "",
      "storage": "0x606CEcE5fd52ce41Df707825Cc9a593e43eE99A6",
      "pricesUSD": {
        "base": 55,
        "optional": 30,
        "taxes": 30,
        "CUSTOM_WATERMARK": 30
      }
    }
  }
}