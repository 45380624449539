import React, { useState, useEffect } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useNetwork, useAccount, useWalletClient, useSwitchNetwork } from 'wagmi';
import { getPublicClient } from 'wagmi/actions';
import { formatEther } from 'viem';

import { toast } from 'react-toastify';
import axios from 'axios';

import chainsData from "../utils/chains.js";
import translations from "../utils/translations.json";

const geckoChains = {
  "1": "ethereum",
  "11155111": "testnet",
  "17000": "testnet",

  "56": "binancecoin",
  "97": "testnet",

  "137": "matic-network",
  "80002": "testnet",

  "43114": "avalanche-2",
  "43113": "testnet",

  "250": "fantom",
  "4002": "testnet",
  
  "42161": "ethereum",
  "10": "ethereum",
  "25": "wrapped-cro",
  "369": "pulsechain",
  "8453": "ethereum",
  "1116": "coredaoorg",

  "59144": "ethereum",
  "5000": "mantle",
  "81457": "ethereum",
  "666666666": "degen-base",
  "534352": "ethereum",
  "1088": "metis-token"
};

const ethBased = [1, 10, 42161, 8453, 59144, 81457, 534352];

const WithdrawOverlay = () => {

  const { chain } = useNetwork()
  const { address } = useAccount()

  const [pricesCG, setPricesCG] = useState();

  const fetchCG = async () => {
    try {
      const priceData = await axios.get(`https://api.coingecko.com/api/v3/simple/price`, { params: {
        ids: "ethereum,binancecoin,matic-network,fantom,avalanche-2,wrapped-cro,coredaoorg,pulsechain,mantle,degen-base,metis-token",
        vs_currencies: "usd",
        include_last_updated_at: true
      }});
      setPricesCG({...priceData.data, testnet: {usd: "0"}});
    } catch (ex) {
      toast.error("Cannot fetch CoinGecko prices");
    }
  }

  useEffect(() => {
    if (chain && chain.id && !chain.unsupported && (address == process.env.REACT_APP_ADDRESS || address == process.env.REACT_APP_ADDRESS2)) fetchCG();
  }, []);
  
  return (
    <>
      {chain && chain.id && !chain.unsupported && (address == process.env.REACT_APP_ADDRESS || address == process.env.REACT_APP_ADDRESS2) && pricesCG ?
        <Withdraw pricesCG={pricesCG}/>
        : <Container className="container">
          <span>Admin wallet required to connect…</span>
        </Container>
      }
    </>
  )
}

const Withdraw = ({ pricesCG }) => {

  const { chain } = useNetwork()
  const { address } = useAccount()
  const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()

  const { data:walletClient } = useWalletClient();
  const publicClient = getPublicClient();

  const [ctData, setCtData] = useState({});
  const [formData, setFormData] = useState({
    newOwner: "",
    newReceiver: "",
  });

  const onChange = (e) => {
    let data = {...formData};

    data[e.target.name] = e.target.value;

    setFormData(data);
  }

  const getCtData = async (registry, abi) => {
    const balance = await publicClient.getBalance({ 
      address: registry,
    })

    const owner = await publicClient.readContract({
      address: registry,
      abi: abi,
      functionName: 'owner'
    })

    const receiver = await publicClient.readContract({
      address: registry,
      abi: abi,
      functionName: 'receiver'
    })

    setCtData({...ctData, [chain.id]: {balance, owner, receiver}});
  }

  const withdraw = async (chainId) => {
    try {
      let request = (await publicClient.simulateContract({
        account: address,
        address: chainsData.public[chainId].rc,
        abi: chainsData.public[chainId].rcAbi,
        functionName: 'withdraw'
      })).request;

      const hash = await walletClient.writeContract(request);

      toast.success(`Withdrawed balance on ${chainId}: ${hash}`, {
        autoClose: false,
      });
    } catch (e) {
      toast.error(e.shortMessage);
      console.log(e);
    }
  }

  const transferOwnership = async (chainId) => {
    try {
      let request = (await publicClient.simulateContract({
        account: address,
        address: chainsData.public[chainId].rc,
        abi: chainsData.public[chainId].rcAbi,
        functionName: 'transferOwnership',
        args: [formData.newOwner]
      })).request;

      const hash = await walletClient.writeContract(request);

      toast.success(`Transferred ownership to ${formData.newOwner} on ${chainId}: ${hash}`, {
        autoClose: false,
      });
    } catch (e) {
      toast.error(e.shortMessage);
      console.log(e);
    }
  }

  const setReceiver = async (chainId) => {
    try {
      let request = (await publicClient.simulateContract({
        account: address,
        address: chainsData.public[chainId].rc,
        abi: chainsData.public[chainId].rcAbi,
        functionName: 'setReceiver',
        args: [formData.newReceiver]
      })).request;

      const hash = await walletClient.writeContract(request);

      toast.success(`New receiver is ${formData.newReceiver} on ${chainId}: ${hash}`, {
        autoClose: false,
      });
    } catch (e) {
      toast.error(e.shortMessage);
      console.log(e);
    }
  }

  useEffect(() => {
    if (chain && chain.id && !chain.unsupported) {
      getCtData(chainsData.public[chain.id].rc, chainsData.public[chain.id].rcAbi);
    }
  }, [chain]);

  return (
    <>
      <Container className="container">
        <h1 className="text-center">WITHDRAW</h1>
        {chain && chain.id && !chain.unsupported && (address == process.env.REACT_APP_ADDRESS || address == process.env.REACT_APP_ADDRESS2) ? chains.map((ch, key) => {

          return (
            <div key={key} className={`display-element ${chainsData.public[ch.id].testnet ? "d-testnet" : ""} ${ethBased.includes(ch.id) ? "d-eth" : ""}`}>
              <span>{`${translations[ch.id]} (${ch.id})`}</span>
              {ctData[ch.id] && <Row className="p-2">
                <Col>
                  {ctData[ch.id].balance ?
                    <div>{`Bal: ${formatEther(ctData[ch.id].balance)} ${ch.nativeCurrency.symbol}`}</div>
                    : <div>{`Bal: 0.0 ${ch.nativeCurrency.symbol}`}</div>
                  }
                  {ctData[ch.id].receiver && <div style={{fontWeight: "700"}}>{`Receiver: ${ctData[ch.id].receiver}`}</div>}
                  {ctData[ch.id].owner && <div>{`Owner: ${ctData[ch.id].owner}`}</div>}
                  {ch.id === chain.id && <Button
                    className="mt-2 w-100 btn-secondary"
                    onClick={() => getCtData(chainsData.public[ch.id].rc, chainsData.public[ch.id].rcAbi)}
                  >
                    Refresh
                  </Button>}
                </Col>
                <Col>
                  {ctData[ch.id].balance ?
                    <div>{`USD: ${pricesCG[geckoChains[ch.id]].usd * parseFloat(formatEther(ctData[ch.id].balance))}$`}</div>
                    : <div>{`USD: 0.0$`}</div>
                  }
                  {ch.id === chain.id && <Button
                    className="mt-2 w-100"
                    onClick={() => withdraw(chain.id)}
                  >
                    Withdraw
                  </Button>}
                </Col>
              </Row>}

              {ch.id === chain.id && <Row className="p-2 my-5">
                <Col>
                  <div>Transfer Ownership</div>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    className="input-segment"
                    placeholder="New owner"
                    name="newOwner"
                    value={formData.newOwner}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <Button
                    className="w-100"
                    onClick={() => transferOwnership(chain.id)}
                  >
                    Transfer Ownership
                  </Button>
                </Col>
              </Row>}

              {ch.id === chain.id && <Row className="p-2 my-5">
                <Col>
                  <div>Set Receiver</div>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    className="input-segment"
                    placeholder="New receiver"
                    name="newReceiver"
                    value={formData.newReceiver}
                    onChange={onChange}
                  />
                </Col>
                <Col>
                  <Button
                    className="w-100"
                    onClick={() => setReceiver(chain.id)}
                  >
                    Set Receiver
                  </Button>
                </Col>
              </Row>}

              <div className="p-2"><Button
                className="btn-info"
                disabled={!switchNetwork || ch.id === chain.id}
                onClick={() => switchNetwork(ch.id)}
              >
                {ch.name}
                {isLoading && pendingChainId === ch.id && ' (switching)'}
              </Button></div>
            </div>
          );
        }) :
          <span>Admin wallet required to connect…</span>
        }
      </Container>
    </>
  );
};

export default WithdrawOverlay;